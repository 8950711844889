<template>
  <div class="container-sm">
    <div class="logo-size">
      <div class="logo-placeholder"></div>
      <!-- <img :src="require('@/assets/kl_archiv_logo.gif')" alt="logo" class="w-25"> -->
      <router-link to="/" class="text-dark"><h2>kleinanzeigen-archiv</h2></router-link>
    </div>
    <div class="input-group">
      <input type="text" id="main-search" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder="Search" @keyup="performSearch" v-model="searchQuery">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: '',
    };
  },
  mounted() {
    this.clearSearch();
    this.removeQueryFromURL();
  },
  methods: {
    performSearch() {
      if (this.searchQuery.length >= 3) {
        this.$router.push({ name: 'home', query: { query: this.searchQuery } }).catch(() => {});
      }
    },
    clearSearch() {
      this.searchQuery = '';
    },
    removeQueryFromURL() {
      // Entfernt die Suchabfrage aus der URL
      const { query, ...routeWithoutQuery } = this.$route;
      if (Object.keys(query).length > 0) {
        this.$router.replace(routeWithoutQuery).catch(() => {});
      }
    }
  },
  watch: {
    '$route.query.query': {
      handler(newQuery) {
        this.searchQuery = newQuery || '';
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.logo-placeholder {
  height: 200px;
}
</style>
